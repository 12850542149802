<template>
  <v-tooltip color="blue-grey darken-4" :left="left" :top="top" :right="right">
    <template v-slot:activator="{ on }">
      <v-icon color="primary" dark v-on="on">mdi-help-circle-outline</v-icon>
    </template>
    <slot></slot>
  </v-tooltip>
</template>

<script>
  export default {
    props: ['left', 'top', 'right']
  }
</script>